import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Grid,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import VoiceAnimation from '../assets/vapiTalking.gif';
import StaticVapiImg from '../assets/staticVapi.png';
import Vapi from '@vapi-ai/web';

const startCall = async (setCallStatus, vapi) => {
  const assistantOverrides = {
    transcriber: {
      provider: "deepgram",
      model: "nova-2",
      language: "en-US",
    },
    recordingEnabled: false,
    variableValues: {
      name: "John",
    },
    firstMessage: "hello {{name}}, I am Devashish"
  };

  const getAssistantIdFromSession = () => {
    const storedData = sessionStorage.getItem('apiResponse');
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        return parsedData.assistantId || null;
      } catch (error) {
        console.error('Failed to parse session data:', error);
        return null;
      }
    }
    return null;
  };

  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    setCallStatus('loading');
    vapi.start(getAssistantIdFromSession(), assistantOverrides);
    setCallStatus('active');
  } catch (error) {
    
    alert('Microphone permission is needed to make calls. Please enable it in your settings.');
    console.error('Error accessing the microphone or starting the call:', error);
  }
};

export default function VapiAssistant({ setIsUserSpeaking }) {
  const [callStatus, setCallStatus] = useState('inactive');
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  // // const vapi = new Vapi('ed9f83bc-cd5c-45a0-871b-07c318a293e7');
  // const vapi = new Vapi('460c4f8b-9592-4e27-9ec7-47379707cfc8'); //sgrover private
  const vapi = new Vapi('dd14f10a-189a-47fb-95d9-99440b02183e'); //sgrover public key needed.
  
  const isMounted = useRef(true);

//   useEffect(() => {
//     return () => {
//       isMounted.current = false;
//     };
//   }, []);

  const endCall = async (setCallStatus,vapi) => {
    // if (isMounted.current) {
      try {
        await vapi.stop();
        setCallStatus('inactive');
        setOpenFeedback(true);
        window.location.href = "https://talk.glimpass.com";
      } catch (error) {
        console.error('Error ending the call:', error);
      }
    // }
  };

  const handleSubmitFeedback = () => {
    console.log('Feedback submitted:', feedback);
    setOpenFeedback(false);
  };

  return (
    <Box p={2}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
  {callStatus === 'inactive' && <img src={StaticVapiImg} alt="Static Animation" style={{ width: '160px', height: '160px', objectFit: 'cover' }} />}
  {callStatus === 'active' && <img src={VoiceAnimation} alt="Voice Animation" style={{ width: '360px', height: '200px', objectFit: 'cover' }} />}
</Grid>
  <Grid item xs={12}>
          {callStatus === 'loading' && <CircularProgress />}
          {callStatus === 'inactive' && <Button variant="contained" color="primary" onClick={() => startCall(setCallStatus, vapi)}>Start Call</Button>}
          {callStatus === 'active' && <Button variant="contained" color="secondary" onClick={() => endCall(setCallStatus,vapi)}>End Call</Button>}
        </Grid>
      </Grid>
      <Dialog open={openFeedback} onClose={() => setOpenFeedback(false)}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>How was your call experience?</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Your Feedback"
            type="text"
            fullWidth
            variant="outlined"
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitFeedback} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
