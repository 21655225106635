import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import CallSection from './components/CallSection';
import RegistrationForm from './components/RegistrationForm';
import { useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import db from './firebase'; // Import the db config

const App = () => {
    useEffect(() => {
        const fetchData = async () => {
          try {
            const querySnapshot = await getDocs(collection(db, "talk-glimpass"));
            console.log("hihihihi: "+querySnapshot)
            querySnapshot.forEach((doc) => {
              console.log(`${doc.id} => `, doc.data()); // Changed to correctly log objects
            });
          } catch (error) {
            console.error("Error fetching data: ", error);
          }
        };
    
        fetchData();
      }, []);
    
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginForm />} />
                
        <Route path="/:unicode" element={<LoginForm />} />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/medibrief" element={<LoginForm />} />
                <Route path="/call-section" element={<CallSection />} />
                <Route path="/register" element={<RegistrationForm />} />
            </Routes>
        </Router>
    );
};

export default App;
