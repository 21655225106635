// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA6qMr-40GoU9JvS7K4TceX_4szwrrvmHU",
  authDomain: "talk-glimpass.firebaseapp.com",
  projectId: "talk-glimpass",
  storageBucket: "talk-glimpass.appspot.com",
  messagingSenderId: "129672396058",
  appId: "1:129672396058:web:2953d86fd492561a6c40a3",
  measurementId: "G-B063TY0Q3E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export default db;