import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import KeyIcon from '@mui/icons-material/VpnKey';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import '../styles/LoginForm.css';

const LoginForm = () => {
    const [unicode, setUniCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    // Function to store the API response in session storage
    const storeResponseInSession = (data) => {
        sessionStorage.setItem('apiResponse', JSON.stringify(data));
    };

    // Function to handle API validation using Axios
    const validateLogin = async (unicodeToUse) => {
        setLoading(true); // Start the loading state
        setError(''); // Clear any previous errors
        try {
            const response = await axios.post('https://application.glimpass.com/interlogue/get-assistant', {
                unicode: unicodeToUse
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const responseData = response.data;

            // Check if the response is an empty array (invalid) or a populated object (valid)
            if (Array.isArray(responseData) && responseData.length === 0) {
                setError('Invalid UniCode');
            } else if (typeof responseData === 'object' && responseData !== null) {
                storeResponseInSession(responseData); // Store the received object data
                navigate('/call-section');
            } else {
                setError('Unexpected response format');
            }
        } catch (error) {
            setError('Error validating credentials: ' + error.message);
        } finally {
            setLoading(false); // Stop the loading state
        }
    };

    // Function to extract the Unicode query parameter
    const getUnicodeFromQuery = () => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('unicode');
    };

    // Automatically log in if the `unicode` is in the query parameters
    useEffect(() => {
        const unicodeFromQuery = getUnicodeFromQuery();
        if (unicodeFromQuery) {
            setUniCode(unicodeFromQuery);
            validateLogin(unicodeFromQuery);
        }
    }, []);

    // Button handler for "Talk to Assistant"
    const handleTalkToAssistant = () => {
        validateLogin(unicode);
    };

    return (
        <div className="login-form">
            <center><h1>Talk to Assistant</h1></center>
            <TextField
                label="UniCode"
                variant="outlined"
                value={unicode}
                onChange={(e) => setUniCode(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <KeyIcon />
                        </InputAdornment>
                    )
                }}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleTalkToAssistant} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Talk to Assistant'}
            </Button>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default LoginForm;
