import React, { useState } from 'react';
import VoiceAnimation from './VoiceAnimation';
import VapiAssistant from './VapiAssistant';
import '../App.css'; // Ensure your App.css includes booth styles

const CallSection = () => {
    const [isUserSpeaking, setIsUserSpeaking] = useState(false);
    const [isCallConnected, setIsCallConnected] = useState(false);

    const connectCall = () => setIsCallConnected(true);

    const getAssistantIdFromSession = () => {
        const storedData = sessionStorage.getItem('apiResponse');
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                return parsedData.assistantId || null;
            } catch (error) {
                console.error('Failed to parse session data:', error);
            }
        }
        return null;
    };

    return (
        <div className="calling-booth"> {/* Apply booth styles */}
            <header className="booth-header">
                <h5>{getAssistantIdFromSession()}</h5>
            </header>
            <main className="booth-main">
                <VapiAssistant setIsUserSpeaking={setIsUserSpeaking} />
            </main>
        </div>
    );
};

export default CallSection;
